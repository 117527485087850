import { createSlice } from '@reduxjs/toolkit'

export const ChargeBeeSlice = createSlice({
  name: 'ChargeBeeSlice',
  initialState: {
    subscribed: false,
  },
  reducers: {
    setSubscription: (state,action) => {
      state.subscribed = action.payload
    },
   
  },
})

// Action creators are generated for each case reducer function
export const { setSubscription } = ChargeBeeSlice.actions

export default ChargeBeeSlice.reducer