import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authorizationReducer from './Slices/AuthorizationSlice';
import userInfoReducer from './Slices/UserInfoSlice';
import workspaceReducer from './Slices/WorkspaceSlice'; // Ensure correct naming
import updateInfoReducer, { updateInfo } from './Slices/UpdateSlice'; // Ensure correct naming
import chargeBeeReducer from './Slices/ChargeBeeSubscriptionSlice'
// Combine all reducers
const rootReducer = combineReducers({
  authorization: authorizationReducer,
  userInfo: userInfoReducer,
  workspace: workspaceReducer, // Ensure naming consistency
  updateInfo: updateInfoReducer, // Ensure naming consistency
  chargeBeeSubscription : chargeBeeReducer
});

// Configure the store without persistence
const store = configureStore({
  reducer: rootReducer,
});

// Export the store
export default store;
