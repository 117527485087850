import { lazy, Suspense, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { CSpinner } from "@coreui/react";
import "./App.css";
import "./scss/style.scss";
import PaymentPage from "./components/Payments/Payment";

const DashboardLayoutBasic = lazy(() => import("./components/Main"));
const SignIn = lazy(() => import("./components/sign-in/SignIn"));

const Worksapces = lazy(() => import("./components/Worksapces/Worksapces"));
const Reports = lazy(() => import("./components/Reports/Reports"));
const Calendar = lazy(() => import("./components/Calendar/Calendar"));
const Integrations = lazy(() => import("./components/Integrations/Integrations"));
const Support = lazy(() => import("./components/Support/Support"));

const Topics = lazy(() => import("./components/Reports/Topics/Topics"));
const Teach = lazy(() => import("./components/Teach/Teach"));

const Check = lazy(() => import("./components/Check"));
const ManageWorkspace = lazy(() => import("./components/Worksapces/ManageWorkspace"));
const Teams = lazy(() => import("./components/Worksapces/Teams"));
const People = lazy(() => import("./components/Worksapces/People"));
const TeamInfo = lazy(() => import("./components/Worksapces/TeamInfo"));
const ManageAccounts = lazy(() => import("./components/Settings/ManageAccounts"));
const GoogleCalendar = lazy(() => import("./components/sign-in/GoogleCalendar"));
const Feedback = lazy(() => import("./components/Feedback/FeedbackForm"));
const AcceptInvitation = lazy(() => import("./components/Worksapces/AcceptInvitation"));
const SlideDown = lazy(() => import("./utilities/SlideNotification"))
const OAuthCallback = lazy(() => import("./components/sign-in/OauthCallback"))
const Home = lazy(() => import("./components/Home/Home"))


const Dashboard = lazy(
  () => import("./components/MainDashboard/Dashboard")
);

function App() {


  return (
    <>
      <Router>
        <Suspense
          fallback={
            <div className="pt-3 text-center">
              <CSpinner color="primary" variant="grow" />
            </div>
          }
        >

          <Routes>
            <Route path="" element={<DashboardLayoutBasic />}>
              {/* <Route index element={<Dashboard />} /> */}
              <Route index  element={<Navigate to="/home" />} />
              <Route  path="/home" element={<Home />} />
              {/* <Route  element={<Navigate to="/organization" />} /> */}
              <Route path="organization" element={<Worksapces />}>
                <Route path="" element={<ManageWorkspace />}>
                  
                  <Route path="teams" element={<Teams />} />
                  <Route path="people" element={<People />} />
                </Route>
                <Route path="team/:w_id/:t_id" element={<TeamInfo />} />
              </Route>

              <Route path="manage" element={<ManageAccounts />} />
              <Route path="reports" element={<Reports />} />
              <Route path="topics/:id" element={<Topics />} />
              <Route path="calendar" element={<Calendar />} />
              <Route path="integration" element={<Integrations />} />
              <Route path="support" element={<Support />} />
              <Route path="check" element={<Check />} />
              <Route path="team" element={<Teams />} />
              <Route path="google-calendar" element={<GoogleCalendar />} />
              <Route path="teach" element={<Teach />} />
            </Route>
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/accept-invite" element={<AcceptInvitation />} />
            <Route path="oauth/callback" element={<OAuthCallback />} />
            <Route path="/payments" element={<PaymentPage/>} />
            
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
