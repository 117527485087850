
import { Stack, TextField, Button, MenuItem, Select, InputLabel, FormControl, Container, Typography, Box } from "@mui/material";
import { useState } from "react";
import signin_image from '../../Assets/signin_banner.webp'
import liznr from '../../Assets/LiznrLight.webp'


const PaymentPage = () => {
    const [cardNumber, setCardNumber] = useState("");
    const [expiry, setExpiry] = useState("");
    const [cvv, setCvv] = useState("");
    const [planId, setPlanId] = useState("basic-plan"); // Default plan

    const handleSubscribe = async () => {
        const response = await fetch("/api/create-subscription/", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ cardNumber, expiry, cvv, planId }),
        });

        const data = await response.json();
        if (data.success) {
            alert("Subscription created successfully!");
        } else {
            alert("Payment failed. Try again.");
        }
    };

    return (
        <Container maxWidth={false} sx={{ height: "100%", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Stack direction={"column"} spacing={2} sx={{ width: "50%", padding: "40px 100px" }}>
                <img src={liznr} alt="liznr" style={{ width: "100px", height: "auto" }} />
                {/* <Box sx={{ mt: 4, p: 3, boxShadow: 3, borderRadius: 2, bgcolor: "background.paper" }}> */}
                <Typography variant="h5" gutterBottom>
                    Subscribe to a Plan
                </Typography>
                <TextField
                    fullWidth
                    label="Card Number"
                    variant="outlined"
                    margin="normal"
                    value={cardNumber}
                    onChange={(e) => setCardNumber(e.target.value)}
                />
                <TextField
                    fullWidth
                    label="Expiry Date"
                    variant="outlined"
                    margin="normal"
                    value={expiry}
                    onChange={(e) => setExpiry(e.target.value)}
                />
                <TextField
                    fullWidth
                    label="CVV"
                    variant="outlined"
                    margin="normal"
                    value={cvv}
                    onChange={(e) => setCvv(e.target.value)}
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>Plan</InputLabel>
                    <Select value={planId} onChange={(e) => setPlanId(e.target.value)}>
                        <MenuItem value="basic-plan">Basic</MenuItem>
                        <MenuItem value="premium-plan">Premium</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={handleSubscribe}
                >
                    Subscribe
                </Button>
                {/* </Box> */}
            </Stack>
            <Stack direction={"column"} spacing={2} sx={{ width: "50%", padding: "40px 60px" }}>
                <img
                    src={signin_image}
                    alt=""
                    style={{ width: "100%",height:"450px", objectFit: "contain" }}
                />
            </Stack>

        </Container>
    );
};

export default PaymentPage;
