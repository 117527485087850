import { createSlice } from '@reduxjs/toolkit'

export const updateInfoSlice = createSlice({
  name: 'update',
  initialState: {
    team_update:false
  },
  reducers: {
    
    updateTeam:(state,action)=>{
      state.team_update = action.payload
    }
   
  },
})

// Action creators are generated for each case reducer function
export const { updateInfo,updateTeam } = updateInfoSlice.actions

export default updateInfoSlice.reducer